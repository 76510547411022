import React, { FC } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import Lottie from "react-lottie";
import developer from "./lotties/developer.json";
import technology from "./lotties/developer2.json";
import job from "./lotties/work.json";
import me from "./img/me.jpeg";

const App: FC = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-dark navbar-dark sticky-top">
        <div className="container">
          <a href="#home" className="navbar-brand">
            Tomasz Grabowski
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navmenu">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a href="#about" className="nav-link">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a href="#technologies" className="nav-link">
                  Technologies
                </a>
              </li>
              <li className="nav-item">
                <a href="#resume" className="nav-link">
                  Resume
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="bg-dark text-light p-5 text-center" id="home">
        <div className="container">
          <div className="d-md-flex">
            <div className="pt-5">
              <h1 className="display-3 pb-1">
                <i className="bi-code-slash"></i> Tomasz
                <span className="text-danger"> Grabowski</span>
              </h1>
              <h2>Father, husband and a Senior Software Developer</h2>
              <p className="lead pt-3">
                This is my personal website, where you can find most important
                information about me, my professional career and by hobbies.
              </p>
              <a
                href="mailto:tomaszgrabowski07@gmail.com"
                className="btn btn-lg btn-danger mt-4"
              >
                Contact Me!
              </a>
            </div>
            <div className="w-50 d-none d-lg-block">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: developer,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                isStopped={false}
                isPaused={false}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5" id="about">
        <h2 className="text-center pt-5">
          <i className="bi bi-chevron-right"></i> About Me
        </h2>
        <div className="container lead">
          <div className="row mt-5 align-items-center">
            <div className="col-lg-5 text-center text-end my-4">
              <img
                src={me}
                alt="Software Developer"
                className="img-fluid border border-5 w-50 mx-auto rounded-circle img-thumbnail"
              />
            </div>
            <div className="col-lg-7 text-center text-lg-start">
              I'm a software developer, husband and a father of two beautiful
              daughters. Working daily in IHS's Gdańsk office. Most of my time
              I'm spending working on frontend tasks, but also backend doesn't
              make any problems for me. In my current project I'm working with
              Angular2+, AngularJS, React and .Net Core microservices as backend
              solutions. I'm an alumnus of PJATK academy.
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 bg-light" id="technologies">
        <h2 className="text-center pt-5">
          <i className="bi bi-chevron-right"></i> Technologies
        </h2>
        <div className="container lead">
          <div className="row mt-5">
            <div className="col-lg-6 pe-5 text-start text-lg-end">
              <ul className="list-unstyled">
                <li>
                  <h4>
                    <i className="bi-palette"></i> Frontend
                  </h4>
                  <ul className="list-unstyled pb-1">
                    <li className="pe-4">
                      TypeScript / JavaScript{" "}
                      <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star text-danger"></i>
                    </li>
                    <li className="pe-4">
                      {" "}
                      React <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                    </li>
                    <li className="pe-4">
                      {" "}
                      Angular <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star text-danger"></i>
                    </li>
                    <li className="pe-4">
                      {" "}
                      HTML5 <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                    </li>
                    <li className="pe-4">
                      {" "}
                      CSS3 <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                    </li>
                    <li className="pe-4">
                      {" "}
                      SCSS <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star-fill text-danger"></i>
                      <i className="bi bi-star text-danger"></i>
                    </li>
                  </ul>
                  <li>
                    <hr />
                    <h4>
                      <i className="bi-code-slash"></i> Backend
                    </h4>
                    <ul className="list-unstyled">
                      <li className="pe-4">
                        .Net / .Net Core{" "}
                        <i className="bi bi-star-fill text-danger"></i>
                        <i className="bi bi-star-fill text-danger"></i>
                        <i className="bi bi-star text-danger"></i>
                      </li>
                      <li className="pe-4">
                        Node / Express{" "}
                        <i className="bi bi-star-fill text-danger"></i>
                        <i className="bi bi-star text-danger"></i>
                        <i className="bi bi-star text-danger"></i>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr />
                    <h4>
                      <i className="bi-phone"></i> Mobile
                    </h4>
                    <ul className="list-unstyled">
                      <li className="pe-4">
                        React Native{" "}
                        <i className="bi bi-star-fill text-danger"></i>
                        <i className="bi bi-star text-danger"></i>
                        <i className="bi bi-star text-danger"></i>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr />
                    <h4>
                      <i className="bi-brush"></i> UI / UX
                    </h4>
                    <ul className="list-unstyled pb-1">
                      <li className="pe-4">
                        Photoshop{" "}
                        <i className="bi bi-star-fill text-danger"></i>
                        <i className="bi bi-star-fill text-danger"></i>
                        <i className="bi bi-star text-danger"></i>
                      </li>
                    </ul>
                  </li>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: technology,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                isStopped={false}
                isPaused={false}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 bg-success text-light" id="resume">
        <h2 className="text-center pt-5">
          <i className="bi bi-chevron-right"></i> Resume
        </h2>
        <div className="container lead">
          <div className="row mt-5">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="w-100">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: job,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  isStopped={false}
                  isPaused={false}
                />
              </div>
            </div>
            <div className="col-lg-6 text-start text-lg-start pe-5">
              <ul className="list-unstyled">
                <li>
                  <h4>IHS Markit</h4>
                  <i>2018 - 2022</i>
                  <h5>Senior Software Engineer</h5>
                  <ul className="list pb-1">
                    <li className="ps-4">
                      Developing of backend (.Net Core) and frontend (Angular,
                      Angular.js and React) solutions
                    </li>
                    <li className="ps-4">
                      Setting up initial technology path for new projects
                    </li>
                    <li className="ps-4">
                      Drive internal Knowledge Sharing Sessions project
                    </li>
                    <li className="ps-4">
                      Drive internal trainings for new employees
                    </li>
                  </ul>
                  <hr />
                </li>
                <li>
                  <h4>Holte Software Poland</h4>
                  <i>2013 - 2018</i>
                  <h5>Software Engineer</h5>
                  <ul className="list pb-1">
                    <li className="ps-4">
                      Developing of backend (.Net) and frontend (Angular.js and
                      React) solutions
                    </li>
                    <li className="ps-4">
                      Developing mobile applications in Xamarin (.Net)
                    </li>
                  </ul>
                  <hr />
                </li>
                <li>
                  <h4>Oke Poland</h4>
                  <i>2013 - 2013</i>
                  <h5>Software Engineer</h5>
                  <ul className="list pb-1">
                    <li className="ps-4">
                      Developing of backend (.Net) and frontend (ASP.MVC)
                      solutions
                    </li>
                  </ul>
                  <hr />
                </li>
                <li>
                  <h4>Manpower Group for Intel Poland</h4>
                  <i>2011 - 2013</i>
                  <h5>Software Tester / Supervisor</h5>
                  <ul className="list pb-1">
                    <li className="ps-4">
                      Managing work for group of 5 testers
                    </li>
                    <li className="ps-4">
                      Manual and automatic testing for USB 3.0 project
                    </li>
                  </ul>
                  <hr />
                </li>
                <li>
                  <h4>Police Headquarter</h4>
                  <i>2008 - 2011</i>
                  <h5>IT Specialist</h5>
                  <ul className="list pb-1">
                    <li className="ps-4">
                      Developing of ASP.Net and WinForms solutions
                    </li>
                    <li className="ps-4">Users support</li>
                    <li className="ps-4">Maintaining of internal networks</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <footer className="py-3 bg-dark text-light ">
        <div className="container d-flex flex-row justify-content-around align-items-center">
          <div>
            <a
              href="mailto:tomaszgrabowski07@gmail.com"
              className="nav-link text-light"
            >
              <i className="bi-envelope"></i> tomaszgrabowski07(at)gmail.com
            </a>
            <a href="#" target="_blank" className="nav-link text-light">
              <i className="bi-facebook"></i> tomekgrabowski
            </a>
            <a
              href="skype:tomaszgrabowski.pl?call"
              className="nav-link text-light"
            >
              <i className="bi-skype"></i> tomaszgrabowski.pl
            </a>
          </div>
          <div>
            <small>
              * not even a single line of css was used to create this site ;)
            </small>
          </div>
        </div>
      </footer>
    </>
  );
};

export default App;
